<!--  -->
<template>
  <div class="OAapproval">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: 'OA审批',
        txtColor: '#000',
        isBack: true,
        isMsg: false,
      }"
    /> -->

    <myApproval />
    <approvalList />
  </div>
</template>

<script>
import myApproval from "./components/myApproval";
import approvalList from "./components/approvalList";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {};
  },
  name: "OAapproval",
  components: { myApproval, approvalList, TopWrapper },
  computed: {},
  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.OAapproval {
  min-height: 100%;
  background: #fafafa;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
</style>
