<!--  -->
<template>
  <div class="myApproval">
    <div
      v-for="(item, index) in topList"
      :key="index"
      class="item"
      @click="goList(item)"
    >
      <div class="img-box">
        <img :src="item.icon" class="top-img" />
        <div class="red-point" v-if="index === 0 && item.count">
          {{ item.count }}
        </div>
      </div>
      <div class="text">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import todo from "@/assets/images/OAapprove/todo.png";
import hasdo from "@/assets/images/OAapprove/hasdo.png";
import hasPropose from "@/assets/images/OAapprove/hasPropose.png";
import accept from "@/assets/images/OAapprove/accept.png";
import { taskNumCount } from "@/api/processApplyInfo";

export default {
  data() {
    return {
      topList: [
        { text: "待处理", icon: todo, count: 0, searchType: 3 },
        { text: "已处理", icon: hasdo, count: 0, searchType: 4 },
        { text: "已发起", icon: hasPropose, count: 0, searchType: 1 },
        { text: "我收到的", icon: accept, count: 0, searchType: 2 },
      ],
    };
  },
  name: "myApproval",
  components: {},
  computed: {},
  created() {
    this.getCounts();
  },
  methods: {
    goList(item) {
      this.$router.push({
        path: "/approvalList",
        query: {
          searchType: item.searchType,
        },
      });
    },

    getCounts() {
      taskNumCount().then((res) => {
        this.counts = res.data;
        let { topList } = this;
        let { data } = res;
        for (let i in topList) {
          for (let j in data) {
            if (topList[i].searchType == data[j].state) {
              topList[i].count = data[j].count;
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.myApproval {
  width: 7.1rem;
  height: 1.95rem;
  background-color: #ffffff;
  border-radius: 0.16rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  margin-left: 0.2rem;

  .item {
    display: flex;
    align-items: center;
    flex-direction: column;
    .img-box {
      margin-bottom: 0.12rem;
      height: 0.86rem;
      width: 0.86rem;
      border-radius: 0.5rem;
      margin-bottom: 0.04rem;
      background: #f0f0f0;
      position: relative;
      .top-img {
        width: 100%;
        height: 100%;
      }
      .red-point {
        position: absolute;
        right: 3px;
        top: 0;
        transform: translateX(50%);
        min-width: 17px;
        height: 17px;
        font-size: 12px;
        color: #fff;
        border-radius: 8px;
        background: #f65f38;
        border: 1px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 17px;
        box-sizing: border-box;
        white-space: nowrap;
        padding: 0 3px;
      }
    }
    .text {
      height: 0.37rem;
      font-size: 0.26rem;
      color: #333333;
      line-height: 0.37rem;
    }
  }
}
</style>
