<!-- 审批列表 -->
<template>
  <div>
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '审批列表',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <ul class="approval-container" :key="key">
      <li class="approval-li" v-for="(item, index) in list" :key="index">
        <div class="top">
          <div class="title">{{ item.dictValue }}</div>
          <img
            :class="{ swipper: true, rotate: !item.swipper }"
            :src="expand"
            @click="handleSwipper(item, index)"
          />
        </div>
        <ul class="approval-li-ul" v-if="!item.swipper">
          <li
            v-for="(subItem, subIndex) in item.children"
            :key="subIndex"
            class="item"
            @click="goCreat(subItem, subIndex)"
          >
            <div class="icon-box">
              <svg
                class="icon appicon"
                aria-hidden="true"
                style="width: 0.5rem; height: 0.5rem"
              >
                <use :xlink:href="`#${subItem.icon || 'shenpi'}`"></use>
              </svg>
            </div>
            <div class="text">{{ subItem.name }}</div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import xuanze from "@/assets/images/OAapprove/icon_xuanze.png";
import { queryProcessType, queryProcessInfoList } from "@/api/processInfo";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      expand: xuanze,
      list: [],
      key: 1,
    };
  },
  name: "myApproval",
  components: { TopWrapper },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      await queryProcessType().then((res) => {
        this.list = res.data;
      });
      let list = this.list;
      for (let i in list) {
        let dictKey = list[i].dictKey;
        queryProcessInfoList({
          type: dictKey,
        }).then((res) => {
          let a = [];
          res.data[dictKey].map((item) => {
            /*H5没有采购单功能*/
            if (!item.tagKey || item.tagKey != "PURCHASE_APPROVE") {
              a.push(item);
            }
          });

          list[i].children = a;
          this.key++;
        });
      }
    },
    handleSwipper(item, index) {
      let swipper = this.list[index].swipper;
      this.list[index].swipper = !swipper;
      this.key++;
    },
    goCreat(item, index) {
      let path = "/approvalCreat";
      if (item.tagKey === "CAR_APPLY_APPROVE") {
        path = "/carMgr";
      }
      this.$router.push({
        path: path,
        query: {
          bizCode: item.bizCode,
          tagKey: item.tagKey,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.approval-container {
  margin-top: 0.2rem;
  margin-left: 0.2rem;
  .approval-li {
    width: 7.1rem;
    background-color: #ffffff;
    border: 1px solid transparent;
    margin-bottom: 0.2rem;
    .top {
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      display: flex;
      justify-content: space-between;
      padding: 0 0.3rem;
      .title {
        height: 0.42rem;
        font-size: 0.3rem;
        font-weight: 600;
        color: #333333;
        line-height: 0.42rem;
      }
      .swipper {
        width: 0.3rem;
        height: 0.3rem;
      }
      .rotate {
        transform: rotate(180deg);
      }
    }
    .approval-li-ul {
      border-radius: 0.16rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-items: center;
      align-items: flex-start;
      .item {
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 1.95rem;
        .icon-box {
          margin-bottom: 0.12rem;
          height: 0.86rem;
          width: 0.86rem;
          border-radius: 0.5rem;
          margin-bottom: 0.1rem;
          background: #f0f0f0;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            font-size: 0.4rem;
          }
        }
        .text {
          font-size: 0.26rem;
          color: #333333;
          line-height: 0.37rem;
        }
      }
    }
  }
}
</style>
