import request from "@/utils/request";
// 获取审批分组
export function queryProcessType(params) {
  return request({
    url: "/process-info/query-process-type",
    method: "GET",
    params: params,
  });
}

// 根据分组获取审批项
export function queryProcessInfoList(params) {
  return request({
    url: "/process-info/queryProcessInfoList",
    method: "get",
    params: params,
  });
}
